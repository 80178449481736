import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

export const userNotificationsStore = defineStore({
    id: 'notificationStore',
    state: () => ({    
        baseUrl : `${import.meta.env.VITE_API_URL}/notification`,
    }),
    actions: {    
        ...storeDbActions(),
 
        async getActiveNotifications( language="en") {
            return await fetchWrapper.get(`${this.baseUrl}/active/${language}`)
                .then(async (message)=>{
                    if(!message) throw new Error('Empty result');
                    return message;

                })
                .catch((error)=>{                               
                    throw error;
                });
        },    

    }
});
