<script setup>
import { onMounted, onUnmounted, watch, ref} from 'vue'
import { RouterView } from 'vue-router';
import { MenuComponent, Status, Breadcrumb } from '@/components';
import { useMenuStore, useAuthStore, userNotificationsStore, useSignalRStore } from '@/stores';
import { activateHints } from '@/helpers';
import ConfirmDialog from 'primevue/confirmdialog';
import { useI18n } from "vue-i18n";
import { useRoute } from 'vue-router';

const i18n = useI18n({ useScope: 'global' })    
const menuStore = useMenuStore();

const notifications = ref([]);
const notificationRecords = ref({});

const breadcrumb = ref(null);
const lastLocale = ref(i18n.locale.value);
const route = useRoute();
menuStore.getAll();
  
var crc32=function(r){for(var a,o=[],c=0;c<256;c++){a=c;for(var f=0;f<8;f++)a=1&a?3988292384^a>>>1:a>>>1;o[c]=a}for(var n=-1,t=0;t<r.length;t++)n=n>>>8^o[255&(n^r.charCodeAt(t))];return(-1^n)>>>0};


async function loadText(){
    notifications.value = [];
    notificationRecords.value = {};

    userNotificationsStore().getActiveNotifications(i18n.locale.value)
        .then(msg=>{
            msg = msg.data;       
            
            var json = localStorage.getItem("notifications."+i18n.locale.value);
            var oldr = (json)?JSON.parse(json):{};
            var newr = {};

            msg.forEach(item => {
                if(item.enabled){

                    var newcrc = crc32(item.caption+item.body);

                    if(!oldr.hasOwnProperty(item.id)){
                        notifications.value.push(item);
                        notificationRecords.value[item.id]={
                            crc : newcrc,
                            visible : true
                        };
                    }
                    else {
                        if(newcrc!=oldr[item.id].crc){
                            oldr[item.id].crc=newcrc;
                            oldr[item.id].visible=true;
                        }                        
                        
                        notificationRecords.value[item.id]=oldr[item.id];
                        
                            
                        if(oldr[item.id].visible)
                            notifications.value.push(item);
                    }
                }
            });

            localStorage.setItem("notifications."+i18n.locale.value,  JSON.stringify(notificationRecords.value));
        })
        .catch(err=>{
            //console.log(err)
        });
}

onUnmounted(() => {
    useSignalRStore().close();
    return undefined;
});

onMounted(() => {
    //console.clear();
    setInterval(()=>{ 
        useAuthStore().checklogin();           
    },1000*60*5); // 5 minute check ??    
    loadText();
});

watch(i18n.locale, (newvalue)=>{
    if(lastLocale.value!=newvalue) loadText()
});

watch(route, (newvalue) => {             
    activateHints();    
});

function hideNotification(id){
    notificationRecords.value[id].visible=false;
    delete notifications[id];

    localStorage.setItem("notifications."+i18n.locale.value,  JSON.stringify(notificationRecords.value)); 
}
 
</script>

<template>
    <div class="container">            
        <MenuComponent />                
        <div class="client">
            <Breadcrumb ref="breadcrumb" />
            <RouterView />
        </div>
    </div>
    <Status/> 
    <ConfirmDialog />

    <div v-if="notifications" style="position : fixed;left: 50%; transform: translateX(-50%);  bottom: 0;" class="col-sm-7 mt-0 mb-3 " >
        <div v-for="item in notifications" class="alert alert-primary text-break row alert-dismissible fade show" >
            <div class="col-sm-1" style="padding-right : 50px;">
                <i class="bi bi-info-circle "  style="font-size:2em; "/>
            </div>            
            <div class="col-sm-10 ">
                <b v-html="item.caption" /><br />
                <span v-html="item.body" /><br />
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="hideNotification(item.id)"></button>
            </div>             

        </div>   
    </div>
</template>

<style>
@import '@/assets/base.css';
</style>
 